
import { dispatchGetPopularKindsProductsList } from '@/store/products/actions';
import { Component, Vue } from 'vue-property-decorator';
import ProductGridCard from '@/components/Products/ProductGridCard.vue';
import { ICreatureKind } from '@/interfaces/products';

@Component({
  components: {
    ProductGridCard,
  },
})

export default class PopularKindsProductsList extends Vue {
  public rating: number = 1;
  public popularKindsProductsList: ICreatureKind[] = [];
  public loadingState: any = {
    page: true,
  };

  public async mounted() {
    this.popularKindsProductsList = await dispatchGetPopularKindsProductsList(this.$store, {});
    this.loadingState.page = false;
  }

  public handleChangePopularKind(id) {
    this.$router.push(`/products/kinds/list/${id}`);
  }
}
